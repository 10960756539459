import { Outlet, Link } from "react-router-dom";

import MenuBar from "../MenuBar";

const PageNotFound = () => {
    return (
        <>
           <MenuBar />
            <div className="content-wrapper">
            <div>
                <center><h1>Page Not Found</h1></center>
            </div>
           </div>
        </>
    )
}

export default PageNotFound;