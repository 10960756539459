import './App.css';
import { Outlet, Link } from "react-router-dom";

const MenuBar = () => {
    return (
        <div className="nav-bar">
            <div className="branding">
                <span className="logo"><Link to="/">Rooftop Labs</Link></span>
            </div>
            <div className="menu-items">
                <Link to="/lit" style={{marginRight: "20px"}}>Lit</Link>
                <a href="https://readsleepless.com" style={{marginRight: "20px"}}>Sleepless</a>
                <a href="mailto:rooftoplabsllc@gmail.com" style={{marginRight: "20px"}}>Contact</a>
            </div>
        </div>
    )
}

export default MenuBar;