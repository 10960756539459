import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Lit from "./pages/Lit";
import LitTerms from "./pages/LitTerms";
import LitPrivacy from "./pages/LitPrivacy";
import PageNotFound from "./pages/PageNotFound";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route exact path="lit" element={<Lit />} />
        <Route exact path="lit/terms" element={<LitTerms />} />
        <Route exact path="lit/privacy" element={<LitPrivacy />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
