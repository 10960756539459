import { Outlet, Link } from "react-router-dom";
import MenuBar from "../MenuBar";

const Lit = () => {
    return (
        <>
           <MenuBar />
            <div className="content-wrapper">
            <div>
                <center><h1>Lit</h1></center>
                <p>A hilarious app for generating fake dating profile screenshots</p>
                <center>
                    <button>Download on App Store</button><br/><br/>
                    <Link to="/lit/terms" style={{marginRight: "20px"}}>Terms of Use</Link>
                    <Link to="/lit/privacy">Privacy Policy</Link>
                </center>
            </div>
           </div>
        </>
    )
}

export default Lit;