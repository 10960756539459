import { Outlet, Link } from "react-router-dom";
import MenuBar from "../MenuBar";

const Home = () => {
    return (
        <>
            { <MenuBar />}
           <div className="content-wrapper">
            <div>
                <h1>Hello, it's nice to meet ya 👋</h1>
                <p>Rooftop Labs is a small shop that designs, builds, and publishes iOS applications.</p>
            </div>
           </div>
        </>
    )
}

export default Home;